<template>
    <HeroBanner :data="bannerData" />

    <SectionStack v-if="modelData.section !== undefined" :data="modelData.section">
        <template #content>
            <div class="service-grid grid sm:grid-cols-2 lg:grid-cols-3 gap-y-16 gap-x-8 lg:gap-x-16">
                <ServiceCard
                    v-for="(item, index) in modelData.content"
                    :key="index"
                    :data="item"
                    :name="'model'"
                    :class="{ 'sm:col-span-2 lg:col-span-1': index === 2 }" />
            </div>
        </template>
    </SectionStack>

    <SectionRow :data="aboutData" />

    <SectionStack v-if="serviceData.section !== undefined" :data="serviceData.section">
        <template #content>
            <div class="service-grid grid sm:grid-cols-2 lg:grid-cols-3 gap-y-16 gap-x-8 lg:gap-x-16">
                <ServiceCard v-for="(item, index) in serviceData.content" :key="index" :data="item" :name="'services'" />
            </div>
        </template>
    </SectionStack>

    <SectionStack v-if="milestoneData.section !== undefined" :data="milestoneData.section" class="bg-#f9f9f9">
        <template #content>
            <div class="grid sm:grid-cols-2 xl:grid-cols-4 gap-y-6 gap-x-6">
                <!-- will use if needed -->
                <!-- <div
                    v-for="(item, index) in processData.content"
                    :key="index"
                    class="process-card relative bg-white rounded-10px py-9 px-7 cursor-default">
                    <div class="absolute top-5 right-5 w-50px h-50px">
                        <img :src="getStrapiMedia(item.icon?.url)" :alt="item.icon?.name" @error="getErrorImg" />
                </div>
                    <div class="text-primary text-4xl sm:text-5xl lg:text-50px leading-none font-bold mb-2 lg:mb-3.5">
                        {{ index && `0${index + 1}` }}
                    </div>
                    <h3 class="text-xl lg:text-22px lg:leading-[1.2] font-light mb-1.5 lg:mb-3 transform-uppercase">
                        {{ item.title }}
                    </h3>
                    <p class="text-base lg:text-lg" v-html="item.description"></p>
                </div> -->
                <div
                    v-for="(item, index) in milestoneData.content"
                    :key="index"
                    class="flex flex-col items-center justify-center">
                    <div class="w-14 h-14 mb-5">
                        <img :src="getStrapiMedia(item.icon?.url)" :alt="item.icon?.name" @error="getErrorImg" />
                    </div>
                    <h3 class="text-4xl font-bold mb-1.5 lg:mb-2 text-primary transform-uppercase">
                        {{ item.title }}
                    </h3>
                    <p class="text-base lg:text-lg font-normal" v-html="item.description"></p>
                </div>
            </div>
        </template>
    </SectionStack>

    <SectionStack v-if="specialtyData.section !== undefined" :data="specialtyData.section">
        <template #content>
            <div class="grid sm:grid-cols-2 xl:grid-cols-5 gap-y-6 gap-x-6">
                <div
                    v-for="(item, index) in specialtyData.content"
                    :key="index"
                    class="relative flex flex-col items-center justify-start text-center">
                    <div class="w-14 h-14 mb-6">
                        <img :src="getStrapiMedia(item.icon?.url)" :alt="item.icon?.name" @error="getErrorImg" />
                    </div>
                    <h3 class="text-lg font-normal leading-26px text-#616161/90">
                        {{ item.title }}
                    </h3>
                    <router-link
                        v-if="item.navigation_link?.internalUrl"
                        :to="{ name: 'page', params: { slug: item.navigation_link?.internalUrl } }" />
                </div>
            </div>
        </template>
    </SectionStack>

    <ContactBlock />
</template>
<script lang="ts">
const path = '/home-page';

export default {
    async beforeRouteEnter(_to, _from, next) {
        try {
            await fetchAPI(path);
            next();
        } catch (e) {
            next({ name: '404' });
        }
    }
};
</script>
<script setup lang="ts">
import { fetchAPI } from '@/utils/fetch-api';
import { getStrapiMedia, getErrorImg } from '@/utils/helpers';

//! Components
import SectionRow from '@/layouts/section-row.vue';
import SectionStack from '@/layouts/section-stack.vue';

import { computed, onServerPrefetch, ref } from 'vue';
import { useHead } from '@unhead/vue';

const seoData: any = ref({});
const bannerData: any = ref([]);
const modelData: any = ref({});
const aboutData: any = ref([]);
const serviceData: any = ref({});
const milestoneData: any = ref([]);
const specialtyData: any = ref([]);

const generateMetaTags = () => {
    return {
        title: () => seoData.value.metaTitle,
        meta: [
            {
                name: 'description',
                content: () => seoData.value?.metaDescription
            }
        ]
    };
};

const metaTags = computed(generateMetaTags);

async function init() {
    try {
        const { hero, model, about, service, milestone, specialty } = await fetchAPI(path);

        // re-map value
        if (hero.section !== null || hero.content.length > 0) {
            bannerData.value = {
                ...hero.section,
                media: hero.media
            };
        }

        if (model.section !== null || model.content.length > 0) {
            modelData.value = {
                section: model.section,
                content: model.content
            };
        }

        if (about.section !== null || about.content.length > 0) {
            aboutData.value = {
                ...about.section,
                media: about.media
            };
        }

        if (service.section !== null || service.content.length > 0) {
            serviceData.value = {
                section: service.section,
                content: service.content
            };
        }

        if (milestone.section !== null || milestone.content.length > 0) {
            milestoneData.value = {
                section: milestone.section,
                content: milestone.content
            };
        }

        if (specialty.section !== null || specialty.content.length > 0) {
            specialtyData.value = {
                section: specialty.section,
                content: specialty.content
            };
        }
    } catch (e) {
        console.log(e);
    }
}

async function fetchSEO() {
    const { seo } = await fetchAPI(path);
    if (seo !== null) {
        seoData.value = seo;

        seo.metaTag.map((el: { attribute: string; title: any; content: any }) => {
            const name = el.attribute !== 'property' ? el.title : null;
            const metaTag: { [key: string]: any; name: any; content: () => any } = {
                [el.attribute]: el.title,
                name,
                content: () => el.content
            };

            metaTags.value.meta.push(metaTag);
        });
    }
}

init();

onServerPrefetch(async () => {
    fetchSEO();

    useHead(metaTags.value);
});
</script>
<style lang="scss"></style>
